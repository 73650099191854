/* AppMenu.css */
.AppMenu {
  position: absolute;
  top: 8vh; /* Adjusted to be below the header */
  left: 0; /* Adjusted to align with the left edge of the browser */
  background-color: var(--header-bg-color);
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  text-align: left; /* Left justify the menu options */
  padding: 5px; /* Add padding to define width */
  max-height: 80vh; /* Ensure the menu does not exceed the viewport height */
  overflow-y: auto; /* Enable vertical scrolling */
}

.AppMenu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Left justify the menu options */
  padding: 5px 10px; /* Adjust padding for better spacing */
  cursor: pointer;
}

.AppMenu-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.AppMenu-item i {
  margin-right: 10px; /* Adjusted to right for left-justified layout */
  padding-left: 5px; /* Reduced padding to the left of the icon */
}

/* Custom scroll bar for AppMenu */
.AppMenu::-webkit-scrollbar {
  width: 8px;
}

.AppMenu::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.AppMenu::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Dark Mode Styles */
.dark-mode .AppMenu {
  background-color: #1a1a1a;
  color: white;
}

.dark-mode .AppMenu-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Light Mode Styles */
.light-mode .AppMenu {
  background-color: #f5f5f5;
  color: black;
}

.light-mode .AppMenu-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
