 .ApplicationList {
  padding: 20px;
  text-align: left;
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--app-bg-color);
}

.ApplicationList-header {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.ApplicationList-content {
  flex-grow: 1;
  overflow-y: auto;
}

.ApplicationList table {
  width: 100%;
  border-collapse: collapse;
}

.ApplicationList th, .ApplicationList td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
}

.ApplicationList tr.selected {
  background-color: rgba(0, 123, 255, 0.3); /* Lighter selected color */
}

.ApplicationList tr:hover:not(.selected) {
  background-color: rgba(0, 123, 255, 0.1); /* Hover color */
}

.ApplicationList-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.ok-button, .cancel-button {
  padding: 10px 20px;
  margin-left: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  color: inherit;
  transition: background-color 0.3s ease;
}

.ok-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.ok-button:hover:not(:disabled), .cancel-button:hover {
  background-color: #e0e0e0;
}

.ok-button i, .cancel-button i {
  margin-right: 5px;
}

/* Dark Mode Styles */
.dark-mode .ApplicationList {
  background-color: #1a1a1a;
  color: white;
}

.dark-mode .ApplicationList tr.selected {
  background-color: rgba(255, 255, 255, 0.2); /* Lighter selected color for dark mode */
}

.dark-mode .ApplicationList tr:hover:not(.selected) {
  background-color: rgba(255, 255, 255, 0.1); /* Hover color for dark mode */
}

.dark-mode .ok-button, .dark-mode .cancel-button {
  background-color: #2a2a2a;
  color: white;
}

.dark-mode .ok-button:hover:not(:disabled), .dark-mode .cancel-button:hover {
  background-color: #3a3a3a;
}

/* Light Mode Styles */
.light-mode .ApplicationList {
  background-color: white;
  color: black;
}

.light-mode .ok-button, .light-mode .cancel-button {
  background-color: #f5f5f5;
  color: black;
}

.light-mode .ok-button:hover:not(:disabled), .light-mode .cancel-button:hover {
  background-color: #e0e0e0;
}
