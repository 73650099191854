.About {
  padding: 20px;
  text-align: left; /* Changed from center to left */
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--app-bg-color); /* Match App body background color */
}

.About-header {
  display: flex;
  justify-content: flex-end;
}

.About-close {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: inherit; /* Inherit color from parent */
}

.About-content {
  flex-grow: 1;
  overflow-y: auto;
}

.dark-mode .About {
  background-color: #070707; /* Match App body background color in dark mode */
  color: white;
}

.light-mode .About {
  background-color: white;
  color: black;
}
