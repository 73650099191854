 .PasswordPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.PasswordPopup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
}

.PasswordPopup-criteria p {
  margin: 5px 0;
  font-weight: bold;
  text-align: left;
}

.PasswordPopup-criteria ul {
  list-style: none;
  padding-left: 20px;
  text-align: left;
}

.PasswordPopup-criteria li {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.PasswordPopup-criteria li i {
  margin-right: 10px;
}

.PasswordPopup-criteria li.valid i {
  color: green;
}

.PasswordPopup-criteria li i {
  color: red;
}

.PasswordPopup-inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.show-password-toggle {
  background: none;
  border: none;
  color: rgb(79, 154, 179); /* Changed to light blue */
  cursor: pointer;
  margin-top: 10px;
}

.PasswordPopup-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 20px; /* Add padding between buttons */
}

.cancel-button, .ok-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  color: inherit;
  transition: background-color 0.3s ease;
}

.cancel-button:hover, .ok-button:hover {
  background-color: #e0e0e0;
}

.cancel-button i, .ok-button i {
  font-size: 28px;
  margin-right: 20px;
}

/* Dark Mode Styles */
.dark-mode .PasswordPopup-content {
  background-color: #1a1a1a;
  color: white;
}

.dark-mode .cancel-button, .dark-mode .ok-button {
  background-color: #2a2a2a;
  color: white;
}

.dark-mode .cancel-button:hover, .dark-mode .ok-button:hover {
  background-color: #3a3a3a;
}

/* Light Mode Styles */
.light-mode .PasswordPopup-content {
  background-color: white;
  color: black;
}

.light-mode .cancel-button, .light-mode .ok-button {
  background-color: #f5f5f5;
  color: black;
}

.light-mode .cancel-button:hover, .light-mode .ok-button:hover {
  background-color: #e0e0e0;
}

/* Criteria list styles */
.PasswordPopup-criteria ul {
  list-style: none;
  padding-left: 20px;
  text-align: left;
}

.PasswordPopup-criteria li {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.PasswordPopup-criteria li i {
  margin-right: 10px;
}

.PasswordPopup-criteria li.valid i {
  color: green;
}

.PasswordPopup-criteria li i {
  color: red;
}

.PasswordPopup-title {
  text-align: left;
  font-size: small;
}
