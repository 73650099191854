.ProfileMenu {
  position: absolute;
  top: 8vh; /* Adjusted to be below the header */
  right: 0; /* Adjusted to align with the right edge of the browser */
  background-color: var(--header-bg-color);
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  text-align: left; /* Left justify the menu options */
  padding: 5px; /* Add padding to define width */
}

.ProfileMenu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Left justify the menu options */
  padding: 5px 10px; /* Adjust padding for better spacing */
  cursor: pointer;
}

.ProfileMenu-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.ProfileMenu-item i {
  margin-right: 10px; /* Adjusted to right for left-justified layout */
  padding-left: 5px; /* Reduced padding to the left of the icon */
}

.ProfileMenu-divider {
  border: none;
  border-top: 1px solid #ccc;
  margin: 5px 0;
}

/* Dark Mode Styles */
.dark-mode .ProfileMenu {
  background-color: #1a1a1a;
  color: white;
}

.dark-mode .ProfileMenu-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Light Mode Styles */
.light-mode .ProfileMenu {
  background-color: #f5f5f5;
  color: black;
}

.light-mode .ProfileMenu-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
