.CookieConsent {
  position: fixed;
  bottom: 15vh;
  left: 10px;
  background-color: var(--header-bg-color);
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  text-align: center;
  padding: 10px;
  max-width: 240px;
}

.CookieConsent-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.accept-button, .decline-button {
  padding: 10px 10px;
  margin: 0px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  color: inherit;
  transition: background-color 0.3s ease;
}

.accept-button:hover, .decline-button:hover {
  background-color: #e0e0e0;
}

.accept-button i, .decline-button i {
  margin-right: 5px;
}

/* Dark Mode Styles */
.dark-mode .CookieConsent {
  background-color: #1a1a1a;
  color: white;
}

.dark-mode .accept-button, .dark-mode .decline-button {
  background-color: #2a2a2a;
  color: white;
}

.dark-mode .accept-button:hover, .dark-mode .decline-button:hover {
  background-color: #3a3a3a;
}

/* Light Mode Styles */
.light-mode .CookieConsent {
  background-color: #f5f5f5;
  color: black;
}

.light-mode .accept-button, .light-mode .decline-button {
  background-color: #f5f5f5;
  color: black;
}

.light-mode .accept-button:hover, .light-mode .decline-button:hover {
  background-color: #e0e0e0;
}
