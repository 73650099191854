/* SignUp.css */
.SignUp-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.SignUp-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
}

.SignUp-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: inherit; /* Inherit color from parent */
}

.SignUp-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.SignUp-button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40vh;
}

.SignUp-button i {
  font-size: 24px;
  margin-right: 10px; /* Add space between icon and text */
}

.SignUp-button.google {
  background-color: #070707;
  color: white;
}

.SignUp-button.microsoft {
  background-color: #070707;
  color: white;
}

.SignUp-button.apple {
  background-color: #070707;
  color: white;
}

/* Dark Mode Styles */
.dark-mode .SignUp-content {
  background-color: #1a1a1a;
  color: white;
}

.dark-mode .SignUp-button.google {
  background-color: #2a2a2a;
  color: white;
}

.dark-mode .SignUp-button.microsoft {
  background-color: #2a2a2a;
  color: white;
}

.dark-mode .SignUp-button.apple {
  background-color: #2a2a2a;
  color: white;
}

/* Light Mode Styles */
.light-mode .SignUp-content {
  background-color: white;
  color: black;
}

.light-mode .SignUp-button {
  background-color: #f5f5f5; /* Off white background */
  color: black;
}

.light-mode .SignUp-button:hover {
  background-color: #e0e0e0; /* Slightly darker on hover */
}

.dark-mode .SignUp-button.google:hover {
  background-color: #3a3a3a; /* Slightly darker on hover */
}

.dark-mode .SignUp-button.microsoft:hover {
  background-color: #3a3a3a; /* Slightly darker on hover */
}

.dark-mode .SignUp-button.apple:hover {
  background-color: #3a3a3a; /* Slightly darker on hover */
}